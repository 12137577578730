.nav-underline .nav-link.active {
    text-decoration: underline;
}

.weighted-score-tab a.nav-link.active {
    color: #004E20;
    background-color: #D3DFD4;
}

.weighted-score-tab .nav-link:not(.active) {
    color: #858A7F;
}

.nav-underline .nav-link:not(.active) {
    color: #858A7F;
}