.insights-button {
  & svg {
    fill: #018219 !important;
  }
  &:focus,
  &:hover {
    & svg {
      fill: #fff !important;
    }
  }
}

.search-icon-button {
  width: 35px !important;
  flex: 0 0 auto !important;
  border: 1px solid #ced4da !important;
  border-left: none !important;

  &:hover, &:focus {
    & .material-symbols,
    .material-symbols-outlined {
      color: #fff !important;
    }
  }
}

.bg-insights {
  background-color: #0182191a !important;
  color: #014e0f !important;
}

.erm-logo-container {
  & svg {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.insights-loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 0px 28px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

.slider-indicator {
  position: absolute;
  transform: translateX(-50%);
  background-color: rgb(1, 130, 25, 0.4);
  transition: left 0.1s ease-out;
  white-space: nowrap;
  bottom: 32px;
  font-size: 0.7rem;
  font-weight: 500;
}

.slider-indicator::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(1, 130, 25, 0.4); 
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}
